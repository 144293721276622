import { Component } from "react";
import React, { useState } from 'react';
import logo from '../../../img/logo.svg';
import { Link } from "react-router-dom";
import { API_URL } from "../../../constant/API_Settings";
import { useEffect } from 'react';
const SummarySheet = (summary) => {


  //---On Load---//		
  useEffect(() => {
    //  getSummerySheet(props)

  }, []);



  return (
    <React.Fragment>

      <div class="">
        <div class="text-end col-md-12  ">
          <Link to="/nozzle-employee-list"><a href="/nozzle-employee-list" class="float-end btn btn-gray">
            Back</a>
          </Link>
        </div>
      </div>
      <div className="form-wrap right-wrap sidebar-end mt-5 ">

        <h3 className="table-title text-center mb-3 ">Summary Sheet</h3>
        <table className="table ">
          <tr>
            <td>Total Fuel Sale</td>
            <td className="text-end text-primary">{summary.summary.total_fuel_sale ? summary.summary.total_fuel_sale : '0.00'}</td>
          </tr>
          {/* <tr>
            <td>Total Loose Oil Sales</td>
            <td className="text-end text-danger">{summary.summary.loose_oil_sale ? summary.summary.loose_oil_sale : '0.00'}</td>
          </tr> */}
          {/* <tr>
            <td>Total Packed Oil and other Product Sales</td>
            <td className="text-end text-primary">{summary.summary.packed_oil_other_sale ? summary.summary.packed_oil_other_sale : '0.00'}</td>
          </tr> */}
        </table>
        <h3 className="table-title text-center mb-3">Total Sales:  {summary.summary.total_sales ? summary.summary.total_sales : '0.00'}</h3>



        <table className="table mt-3">
          <tr>
            <td>Short</td>
            <td className="text-end text-primary">{summary.summary.short ? summary.summary.short : '0.00'}</td>
          </tr>
          <tr>
            <td>Excess</td>
            <td className="text-end text-primary">{summary.summary.excess ? summary.summary.excess : '0.00'}</td>
          </tr>

          <tr>
            <td>Bag Collection</td>
            <td className="text-end text-primary">{summary.summary.bag_collection ? summary.summary.bag_collection : '0.00'}</td>
          </tr>
          <tr>
            <td>Credit Sales-Fuel</td>
            <td className="text-end text-danger">{summary.summary.credit_sales ? summary.summary.credit_sales : '0.00'}</td>
          </tr>
          <tr>
            <td>Credit Sales-NonFuel</td>
            <td className="text-end text-danger">{summary.summary.credit_sales_nonfuel ? summary.summary.credit_sales_nonfuel : '0.00'}</td>
          </tr>
          <tr>
            <td>Digital Payment</td>
            <td className="text-end text-primary">{summary.summary.digital_payment ? summary.summary.digital_payment : '0.00'}</td>
          </tr>
          <tr>
            <td>MLA Coupon</td>
            <td className="text-end text-primary">{summary.summary.mla_coupon ? summary.summary.mla_coupon : '0.00'}</td>
          </tr>
          <tr>
            <td>Fleet Card</td>
            <td className="text-end text-primary">{summary.summary.fleet_card ? summary.summary.fleet_card : '0.00'}</td>
          </tr>
          <tr>
            <td>Testing/Density Checks</td>
            <td className="text-end text-primary">{summary.summary.recoup ? summary.summary.recoup : '0.00'}</td>
          </tr>
          <tr>
            <td>Receipt</td>
            <td className="text-end text-primary">{summary.summary.reciept ? summary.summary.reciept : '0.00'}</td>
          </tr>
          <tr>
            <td>Payment</td>
            <td className="text-end text-primary">{summary.summary.payment ? summary.summary.payment : '0.00'}</td>
          </tr>
          <tr>
            <td>Expense</td>
            <td className="text-end text-primary">{summary.summary.expense ? summary.summary.expense : '0.00'}</td>
          </tr>
         {/* <tr>
          <td>Opening Cash</td>
            <td className="text-end text-primary">{summary.summary.opening_cash ? summary.summary.opening_cash : '0.00'}</td>
          </tr> */}
          <tr>
            <td>Closing Cash</td>
            <td className="text-end text-primary">{summary.summary.closing_cash ? summary.summary.closing_cash : '0.00'}</td>
          </tr>

        </table>
      </div>
    </React.Fragment>


  )
}


export default SummarySheet