import { Component } from "react";
import logo from '../../../img/logo.svg';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

const TabHeader = (progress) => {
 
  

        return(
        

                <ul className="nav nav-tabs mb-3 border-0 form-wrap mt-5" id="myTab" role="tablist">
                 {progress.progress == 13 ?
                  <li className="nav-item" role="presentation">
                  <Link to={"/nozzle-closing"}><a className="nav-link active " 
                    type="button" role="tab" aria-controls="home" aria-selected="false" >Nozzle Closing</a>

                 </Link> </li>: <li className="nav-item" role="presentation">
                 <Link to={"/nozzle-closing"}> <a className="nav-link  " 
                    type="button" role="tab" aria-controls="home" aria-selected="false">Nozzle Closing</a>

                 </Link> </li>}
                 {/* 
                  {progress.progress == 1 ? 
                  <li className="nav-item" role="presentation">
                 <Link to={"/packed-oil-list"}><a className="nav-link active "    
                    type="button"  >Packed Oil and Other Product Sales
                </a> </Link></li>:
                  <li className="nav-item" role="presentation">
                  <Link to={"/packed-oil-list"}><a className="nav-link "  id="packedoil-tab"  >Packed Oil and Other Product Sales
                   </a></Link></li>}
                
                {progress.progress == 2 ? 
                  <li className="nav-item">
                   <Link to={"/loose-oil-list"}> <a className="nav-link active" 
                     type="button" role="tab" aria-controls="looseoil"  aria-selected="false">Loose Oil Sale</a>
                  </Link></li>:
                   <li className="nav-item" role="presentation">
                    <Link to={"/loose-oil-list"}><a className="nav-link" 
                    type="button" role="tab" aria-controls="looseoil"  aria-selected="false">Loose Oil Sale</a>
                </Link></li>}

                */}
                  {/* <Link  to="/credit-sales"> */}
                  {progress.progress == 3 ? 
                 <li className="nav-item" role="presentation">
                   <Link to={"/credit-sales"}><a className="nav-link  active"  
                    type="button" role="tab" aria-controls="creditsales" aria-selected="false" >Credit Sales  </a>
                  </Link></li> :
                  <li className="nav-item" role="presentation">
                 <Link to={"/credit-sales"}><a className="nav-link"  
                  type="button" role="tab" aria-controls="creditsales" aria-selected="false" >Credit Sales  </a>
               </Link> </li>}
                  {/* {progress.progress == 4 ?
                  <li className="nav-item" role="presentation">
                     <Link to={"/fleet-card-sales"}> <a className="nav-link active"  
                     type="button" role="tab" aria-controls="fleetcard" aria-selected="false" >Fleet card sales</a>
                 </Link> </li>: <li className="nav-item" role="presentation">
                 <Link to={"/fleet-card-sales"}> <a className="nav-link" id="fleetcard-tab" 
                     type="button" role="tab" aria-controls="fleetcard" aria-selected="false" >Fleet card sales</a>
                  </Link></li>} */}
                   {/* localStorage.getItem('fleet_status') */}
                  {1 == 2 ? '' : progress.progress == 4 ?<li className="nav-item" role="presentation">
                     <Link to={"/fleet-card-sales"}> <a className="nav-link active"  
                     type="button" role="tab" aria-controls="fleetcard" aria-selected="false" >Fleet Card Sales</a>
                 </Link> </li>: <li className="nav-item" role="presentation">
                 <Link to={"/fleet-card-sales"}> <a className="nav-link" id="fleetcard-tab" 
                     type="button" role="tab" aria-controls="fleetcard" aria-selected="false" >Fleet Card Sales</a>
                  </Link></li>}
                 
                { localStorage.getItem('mla_status') == 2 ? '' :
                  progress.progress == 5 ? <li className="nav-item" role="presentation">
                     <Link to={"/mla-coupon-sales"}><a className="nav-link active"
                    type="button" role="tab" aria-controls="mla" aria-selected="false" disabled>MLA Coupon</a>
                 </Link> </li>:<li className="nav-item" role="presentation">
                     <Link to={"/mla-coupon-sales"}><a className="nav-link "
                    type="button" role="tab" aria-controls="mla" aria-selected="false" disabled>MLA Coupon</a>
                 </Link> </li>}
                 {progress.progress == 6 ?
                  <li className="nav-item" role="presentation">
                    <Link to={"/recoup"}> <a className="nav-link active" 
                    type="button" role="tab" aria-controls="recoup" aria-selected="false" >Testing</a>
                  </Link></li>:
                   <li className="nav-item" role="presentation">
                    <Link to={"/recoup"}><a className="nav-link" 
                   type="button" role="tab" aria-controls="recoup" aria-selected="false" >Testing</a>
                </Link> </li>}
                 {progress.progress == 7 ?
                  <li className="nav-item" role="presentation">
                    <Link to={"/card-sales"}> <a className="nav-link active" 
                    type="button" role="tab" aria-controls="cardsales" aria-selected="false" >Card Sales/ Digital Payment</a>
                </Link></li>: <li className="nav-item" role="presentation">
                <Link to={"/card-sales"}><a className="nav-link" 
                    type="button" role="tab" aria-controls="cardsales" aria-selected="false" >Card Sales/ Digital Payment</a>
                 </Link> </li>}
                  {progress.progress == 8 ?
                  <li className="nav-item" role="presentation">
                    <Link to={"/cashpayment"}> <a className="nav-link active" 
                     type="button" role="tab" aria-controls="cashpayment" aria-selected="false" >Cash Payment</a>
                 </Link> </li>:
                   <li className="nav-item" role="presentation">
                   <Link to={"/cashpayment"}> <a className="nav-link" 
                    type="button" role="tab" aria-controls="cashpayment" aria-selected="false" >Payment Voucher</a>
                 </Link></li>}
               
                 {progress.progress == 10 ?
                  <li className="nav-item" role="presentation">
                   <Link to={"/bag-collection"}>  <a className="nav-link active" 
                    type="button" role="tab" aria-controls="bagcollection" aria-selected="false" >Bag Collection</a>
                </Link>  </li>: <li className="nav-item" role="presentation">
                  <Link to={"/bag-collection"}>  <a className="nav-link" 
                    type="button" role="tab" aria-controls="bagcollection" aria-selected="false" >Bag Collection</a>
                 </Link> </li>}
    
                  {progress.progress == 11 ?
                  <li className="nav-item" role="presentation">
                    <Link to={"/ReceiptVoucher"}><a className="nav-link active" 
                    type="button" role="tab" aria-controls="receiptvoucher" aria-selected="false" >Receipt Voucher</a>
                 </Link>  </li>:
                   <li className="nav-item" role="presentation">
                  <Link to={"/ReceiptVoucher"}>  <a className="nav-link"
                   type="button" role="tab" aria-controls="receiptvoucher" aria-selected="false" >Receipt Voucher</a>
                </Link> </li>}
                 {progress.progress == 12 ?
                  <li className="nav-item" role="presentation">
                    <Link to={"/expense"}> <a className="nav-link active" 
                    aria-selected="false" >Expense</a>
                  </Link></li> :   <li className="nav-item" role="presentation">
                  <Link to={"/expense"}>  <a className="nav-link" 
                    aria-selected="false" >Expense</a>
                 </Link> </li> } 
                 {progress.progress == 9 ?
                  <li className="nav-item" role="presentation">
                   <Link to={"/closepayment"}>  <a className="nav-link active" 
                    role="tab" aria-controls="closingcash" aria-selected="false" >Closing Cash</a>
                 </Link> </li>:
                   <li className="nav-item" role="presentation">
                   <Link to={"/closepayment"}> <a className="nav-link" 
                   role="tab" aria-controls="closingcash" aria-selected="false" >Closing Cash</a>
                </Link> </li>}                          

                </ul>
    
        )
    }


export default TabHeader