import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip' 
import TimePicker from 'rc-time-picker';
import { Link,useLocation} from "react-router-dom";
import moment from 'moment/moment';

const NozzEmpConfigModal = ({ preventNonNumericalInput,handleInput,double,isShowing, hide, errors,handleDelete,success,open_reading,chek_nz, inputValues,handleAdd,handleRemove,handleChange_nozzle_id,fields,dropdownData,nozzledropdownData,handleSubmit, handleInputs }) => {
  const location_details = useLocation()
  return (
  <React.Fragment>

{console.log(inputValues.sugest_start_from,"okok")}
<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">
       
        <div class="modal-header mb-3">
        <h5 class="modal-title" id="staticBackdropLabel">Nozzle Allocation-{moment(inputValues.op_date).format("DD-MM-YYYY")}</h5>
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
          <div class="modal-body">

        <div class="row mb-3 justify-content-center">

        <div class="col-md-12">
        <div class="form-row mb-2">
          <label class="text-secondary mb-2">Employee Name <sup class="star">*</sup> </label>
          <div class="select-container">
            <select id="" class="form-control highlight" name="employee_id" value={inputValues.employee_id} onClick={handleInputs}  onChange={handleInputs}>
            {inputValues.role == "employee" ? <option value=''>select</option>:<option value=''>select</option> } 
              {dropdownData.map((data)=>{
              return <option value={data[0]}>{data[1]}</option>
            })
          }
            </select>
          </div>
          <div className="errorMsg">{errors.employee_id}</div>
        </div>
        <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/user-management-op/nozzle-employee-list" })} class="link-text"><a href="/user-management-op/nozzle-employee-list">Redirect to Add Employee</a></Link>
        {/* <div class="form-row mb-2"> 
        {dropdownData.length == 0 && inputValues.role != "employee"? 
                  <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/users/users" })} class="link-text"> Add Employee</Link>
        : '' } 
        </div> */}
         <div class="form-row mb-2">
           </div>
        <div class="form-row  mb-3">
     
                  <div class="row addfield-wrp">
                  <div class="btn-grp-fxd text-end mt-4">
                        <button class="btn-add mt-2  me-2" onClick={() => handleAdd()}>+</button>
                        <button class="btn-add mt-2  me-2 d-none "  onClick={(event) => handleRemove( event)} >-</button>
                      </div>
                  {fields.map((inpuvalue, idx) => {
                  var er = "nozzle_id"+ idx;
                return (
                  <>
                  <div class="col-md-6">
                    <div class="">
                      <div class="row g-3 align-items-center">
                      
                        <div class="col-12">
                        <label class="text-secondary mb-2">Nozzle Names with Opening Reading <sup class="star">*</sup> </label>
                      
                        {/* <div class="select-container">
                          <select id={"nozzle_id"+ idx} class="form-control  nozzle_ids" name="nozzle_id"  onChange={(event) => handleChange_nozzle_id(idx, event)} >
                              <option value='0'>select</option>
                              {nozzledropdownData.map((data)=>{
                
                               return <option value={data[0]}>{data[1]}</option>
                            })
                          }
                          </select>
                        </div>  */}
                        <div class="select-container">
                          <select id={"nozzle_id"+ idx} class="form-control  nozzle_ids" value={inpuvalue} name="nozzle_id"   onChange={(event) => handleChange_nozzle_id(idx, event)} >
                              <option value='0'>select</option>
                              {nozzledropdownData.map((data)=>{
                                  // return <option  class={chek_nz.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                  return  <option style={chek_nz.includes(data[0]) == true ? { display: 'none' } : {}} value={data[0]}>
                                  {data[1]}
                                </option>
                            })
                          }
                          </select>
                        </div>
                        <div className="errorMsg">{errors["nozzle_id"+idx]}</div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="">
                      <div class="row g-3 align-items-center">
                        <div class="col-12">
                          <label  class="col-form-label mb-2">Opening Reading</label>
                               <input type="text"  name="opening_reading"  id={"opening_reading"+idx}  readOnly class="form-control" aria-describedby="passwordHelpInline"/>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                  {idx!==0 ?
                  <button class="btn-add mt-4 ms-1" onClick={(e) => handleDelete(idx)}>-</button>:''}</div>
                      </>
                        );
                      })}
                      
                </div>
                 
           
          
          <div class="row mt-3" >
            <div class="col-md-6">
              <label  class="col-form-label mb-2">Start Time <sup class="star">*</sup> </label>
                <div className="digitaltimepicker">
                <TimePicker
                    showSecond={false} 
                    defaultValue={(!inputValues.start_from) ? inputValues.sugest_start_from : inputValues.start_from }
                    name="start_from"
                    onChange={handleInput}
                    use12Hours
                  />   
                  </div>  
                <div className="errorMsg">{errors.start_from}</div>  
            </div>
           {/*} <div class="col-md-5">
            <label  class="col-form-label mb-2">Opening Cash</label>
              <input type="number"  name="opening_cash" value={inputValues.opening_cash} onChange={handleInputs} class="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} />
            </div> */}
          </div>
        </div>            
        </div>

        <div class="col-md-10">
          
     
        </div>
        </div>
      </div>
      <div class="modal-footer text-center">
     {console.log(double,"doubledouble")} 
      {double===false ?   <a class="btn btn-primary " type="submit" disabled>SAVE</a>: <a class="btn btn-primary " type="submit" onClick={handleSubmit}>SAVE</a>}
      </div>
      </div>
    </div>
</React.Fragment>
  );
}
       export default NozzEmpConfigModal;