import React from "react";
import { Component } from "react";
import '../../App.css'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from 'axios';
import { selling_mode } from "../../constant/constant";
import { packing_unit } from "../../constant/constant";
import { API_URL } from "../../constant/API_Settings";
import { account_type } from "../../constant/constant";
import { Link } from "react-router-dom";
class NonfuelConfigReport extends Component {
	constructor(props) {
        super(props);
        console.log(this.props.location.backpage)
        this.state = {
       report:[],
       fuel:[],
       tank:[],
       nozzle:[],
       rack_product:[],
       rack:[],
       product:[],
       customer:[],
       creditor_debtor:[],
       payment_methods:[],
       bank_details:[],
       fleet_card:[],
       mla_coupon:[],
       employee:[],
       vendor:[],
       asset:[],
       other_income:[],
       expense:[],
       dealership:[],
       tanker:[],
       task:[],
       fuel_unload:[],
       user:[],
       backPage: this.props.location.backpage,
      
        }
		this.handleChange = this.handleChange.bind(this)
	}

  componentDidMount() {
    this.check_url();
  }
  //------------------------url
 check_url=() =>{
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			console.log(responseData)
			if(responseData.allowurl == 1)
			{
        if (this.props.location.pathname == "/config-report/config-report") {
          this.props.history.push(this.props.location.pathname);
          
        } 
        else {
          this.props.history.push(responseData.url);
        }
				// this.history.push(responseData.url);
				this.check_meta_date();
			}
			else{
				
			this.props.history.goBack();
			}

			return responseData;
		})
		.catch(e => {
		});

}
check_meta_date=() =>{

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
		});

}
//--------------------------//
handleChange = (event) => {

	
	this.setState({
	  [event.target.name]: event.target.value,
	})
}
		handleSubmit = () => {
			
			
		}
        componentDidMount()
        {
        this.getReport()
        }
        getReport=(id)=>{
          axios({
            url: API_URL+"/get-user-config",
            method: "GET",
            data: {id:id},
            headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
            }
         }).then(res=>{
          console.log(res.data.customer_data)
            var { report, fuel ,tank,nozzle,rack_product,rack,product,customer,creditor_debtor,payment_methods,bank_details,fleet_card,mla_coupon,employee,vendor,asset,other_income,expense,dealership,tanker,task,fuel_unload,user} = this.state 
         
         // res.data.pump_details.forEach(elem=>{
        //   console.log(elem)
        report.push({
            
            dealer_name: res.data.pump_details.dealer.dealer_name,
            pump_name:res.data.pump_details.pump_name,
            pump_address: res.data.pump_details.pump_address,
        })
          
          //  })
         
          res.data.customer_data.forEach(elem=>{
            console.log(elem);
            customer.push({
            
             name:elem.name,
             email:elem.email,
             phone:elem.phone,
             address:elem.address,
             credit_limit:elem.credit_limit,
             credit_limit_period:elem.credit_limit_period,
             whatsapp_no:elem.whatsapp_no,
             debit_credit:elem.debit_credit,
             opening_balance:elem.opening_balance,
             vehicle_number:elem.vehicle_number

               
                })
          })

          res.data.creditor_debtor.forEach(elem=>{
            console.log(elem);
            creditor_debtor.push({
            
             name:elem.name,
             email:elem.email,
             phone:elem.phone,
             address:elem.address,
             credit_limit:elem.credit_limit,
             credit_limit_period:elem.credit_limit_period,
             whatsapp_no:elem.whatsapp_no,
             debit_credit:elem.debit_credit,
             opening_balance:elem.opening_balance
               
                })
          })

          res.data.payment_methods.forEach(elem=>{
            console.log(elem);
            payment_methods.push({
            
             method_name:elem.method_name,
             settlement_time:elem.settlement_time,
             ac_head:elem.ac_head,
             

               
                })
          })
    
          res.data.user.forEach(elem=>{
            console.log(elem);
            user.push({
            
              name:elem.name,
              phone:elem.phone,
              email:elem.email,
              user_name:elem.user_name,
              role_name: elem.role?elem.role.role_name : 'Default Owner'

               
                })
          })

          res.data.task.forEach(elem=>{
            console.log(elem);
            task.push({
            task:elem.task_name,
            description:elem.description,
            last_action:elem.last_action,
            frequency:elem.frequency,
            frequency_unit:elem.frequency_unit, 
            next_action:elem.next_action, 
            remind_before:elem.remind_before,
            remind_from:elem.remind_from,
            stop_after:elem.stop_after, 
            stop_after_unit:elem.stop_after_unit,

               
                })
          })

          res.data.tanker.forEach(elem=>{
            console.log(elem);
            tanker.push({
            
              reg_number:elem.reg_number,
              total_capacity:elem.total_capacity,
              no_of_containers:elem.no_of_containers,
              total_capacity:elem.total_capacity,
              capacity_of_con1:elem.capacity_of_con1,
              capacity_of_con2:elem.capacity_of_con2,
              capacity_of_con3:elem.capacity_of_con3,
              capacity_of_con4:elem.capacity_of_con4,
              income:elem.income,
              expense:elem.expense,
                })
          })
          
          res.data.dealership.forEach(elem=>{
            var fuel_names = []

            elem.get_pump.fueltype.map((fuel_type,key)=>{
              console.log(elem)
              fuel_names.push(fuel_type.fuel_name)              
            })

            console.log(fuel_names)

            
            dealership.push({
              dealer_name:elem.get_pump.dealer.dealer_name,
              address:elem.address,
              phone:elem.phone,
              email:elem.email,
              fuel_names:fuel_names,
              debit_credit:elem.debit_credit,
              opening_balance:elem.opening_balance,
              supply_terminal:elem.supply_terminal,
              lead_time:elem.lead_time,
             

               
                })
          })


          res.data.other_income.forEach(elem=>{
            console.log(elem);
            other_income.push({
            
              accounts_head:elem.accounts_head,
             

               
                })
          })

         

          res.data.expense.forEach(elem=>{
            console.log(elem);
            expense.push({
            
              accounts_head:elem.accounts_head,
              limit_amount:elem.limit_amount,

               
                })
          })

          let fuel_unload_data=res.data.fuel_unload;
            console.log(fuel_unload_data);
            fuel_unload.push({
            
              fuel_unload: fuel_unload_data,

               
                })
          

          res.data.employee.forEach(elem=>{
            console.log(elem);
            employee.push({
            
              name:elem.name,
              address:elem.address,
              phone:elem.phone,
              email:elem.email,
              designstion:elem.designation,
              ac_name:elem.ac_name,
              user_name:elem.user_name,
              debit_credit:elem.debit_credit,
              opening_balance:elem.opening_balance,

                })
          })

          res.data.fleet_card.forEach(elem=>{
            console.log(elem);
            fleet_card.push({
            
              amount:elem.amount,
              accounts_head:elem.get_trans_name.accounts_head,
             

               
                })
          })

          res.data.vendor.forEach(elem=>{
            console.log(elem);
            vendor.push({
            
              name:elem.ac_name,
              address:elem.address,
              phone:elem.phone,
              email:elem.email,
              credit_period:elem.credit_period,
              product_name:elem.product_name,
              debit_credit:elem.debit_credit,
              opening_balance:elem.opening_balance,
 
                })
          })

          res.data.mla_coupon.forEach(elem=>{
            console.log(elem);
            mla_coupon.push({
            
              amount:elem.amount,
              accounts_head:elem.get_mla_trans_name.accounts_head,
             

               
                })
          })

          res.data.asset.forEach(elem=>{
            console.log(elem);
            asset.push({
            
              asset_name:elem.asset_name,
              date_of_purchase:elem.date_of_purchase,
              purchase_price:elem.purchase_price,
              current_price:elem.current_price,
               
                })
          })


          
          res.data.bank_details.forEach(elem=>{
            console.log(elem);
            bank_details.push({
            
              bank_name:elem.bank_name,
              address:elem.address,
              phone:elem.phone,
              email:elem.email,
              opening_balance:elem.opening_balance,
              branch:elem.branch,
              account_type:elem.account_type,
              account_number:elem.account_number,
              digital_payment:elem.get_payment_methods?elem.get_payment_methods.method_name : '' ,
              ac_name:elem.ac_name,
             

               
                })
          })

   
    
          res.data.rack_product_list.forEach(elem=>{
            console.log(elem)
          rack_product.push({
          
          product_name:elem.product_name,
       
          total_stock:elem.total_stock,
          current_stock:elem.current_stock,
          godown_stock:elem.godown_stock,
          rack_total_stock:elem.rack_total_stock,
          
             
              })
        })
        res.data.rack_stock.forEach(elem=>{
          console.log(elem)
        rack.push({
        
        rack_name:elem.rack_name,
     
       rack_total_stock:elem.rack_total_stock,
       
        
           
            })
      })
      
      res.data.product_list.forEach(elem=>{
        product.push({
        
          product_name:elem.product_name,
          unit:elem.unit,
          quantity:elem.quantity,
          selling_mode:elem.selling_mode,
          product_full_name:elem.product_full_name,
          total_stock:elem.total_stock,
          purchase_price:elem.purchase_price,
          selling_price:elem.selling_price,
          margin_price:elem.margin_price,
          margin_price_loose:elem.margin_price_loose,
          selling_price_loose:elem.selling_price_loose,
          stock_value:elem.stock_value,
           
            })
      })

          this.setState({report:report ,fuel:fuel,tank:tank,nozzle:nozzle,rack_product:rack_product,rack:rack,product:product
         })

         console.log(res.data)
        //  this.setModalShow(true)
      
            }).catch(err=>{
               
            })
           
      }
    

    render() {
    
        return(
        
       
  <div className="main-container page-wrap">
	<div className="d-md-flex">
{/* <Sidebar_Progress progress={11}/> */}
{this.props.location.pathname == "/config-report/config-report" ? (
<Sidebar_Progress
  path={this.props.location.pathname}
  progress={11}/>
  ) : (
  <Sidebar_Progress progress={11} />
            )}
		<div className="content-area">
<LoginHeader page_title="" />


<div className="container pb-3">
	<div className="row justify-content-center form-container report">
      {console.log(this.state.backPage, "ggg")}
        {this.props.location.pathname == "/config-report/config-report" ? (
            <div class="col-md-12 mb-3">
              <Link to={this.state.backPage || "/dashboard"}>
                <a href="#" class="float-end btn btn-gray">
                  Go Back
                </a>
              </Link>
            </div>
          ) : (
            <div class="col-md-12 mb-3">
              {/* <Link to="/target-config">task-config */}
              <Link to="/task-config">
                <a href="/task-config" class="float-end btn btn-gray">
                  Back
                </a>
              </Link>
            </div>
          )}
        <div className="col-md-12">
			<div className="form-wrap list-wrp">
      <h3 className="table-title text-center"> NonFuel Configuration Summary</h3>
      <div>{this.state.confg_date}</div>
      <h3 className="text-start">Non Fuel Products</h3>

<div className="row mb-3  py-2">
<div className="col-md-12">
<div className="table-widget">
      <table className="table border">
<thead>
<tr>
<th>Product Name</th>
<th>Unit</th>
<th>Quantity</th>
<th>Selling Mode</th>
<th>Product Full Name</th>
<th>Total Stock</th>
<th>Purchase Price</th>
<th>Selling Price</th>
<th>Loose Selling Price</th>
<th>Margin Price</th>
<th>Margin Loose Price</th>
<th>Stock Value</th>


  </tr>
  </thead>
  <tbody>
  {

this.state.product.map((obj) => { 
console.log(obj)
return(	
<>
<tr>
  <td> {obj.product_name} </td>
  <td>{packing_unit[obj.unit.toString()] ? packing_unit[obj.unit.toString()] :''   }</td>
  <td> {obj.quantity} </td>
  <td> {selling_mode[obj.selling_mode] ?selling_mode[obj.selling_mode.toString()]:''} </td>
  <td> {obj.product_full_name} </td>
  <td> {obj.total_stock} </td>
  <td> {obj.purchase_price} </td>
  <td> {obj.selling_price} </td>
  <td> {obj.selling_price_loose} </td>
  <td> {obj.margin_price} </td>
  <td> {obj.margin_price_loose} </td>
  <td> {obj.stock_value} </td>

  
  {/* <td>{obj.godown_stock}</td>
 <td>{obj.rack_total_stock}</td> */}
  </tr>
  </>
)})}

  </tbody>
  </table>
  </div>

</div>


  </div>   
      <h3 className="text-start mt-5">Rack Product List </h3>

<div className="row mb-3  py-2">
<div className="col-md-12">
<div className="table-widget">
      <table className="table border">
<thead>
<tr>
<th>Product Name</th>
<th>Total Stock </th>
<th>Main Godown Stock</th>
 <th>Rack Stock</th>
  </tr>
  </thead>
  <tbody>
  {

this.state.rack_product.map((obj) => { 
console.log(obj)
return(	
<>
<tr>
  <td> {obj.product_name} </td>
  <td>{obj.total_stock}</td>
  <td>{obj.godown_stock}</td>
 <td>{obj.rack_total_stock}</td>
  </tr>
  </>
)})}

  </tbody>
  </table>
  </div>

</div>


  </div>
  <h3 className="text-start">Rack Stock</h3>

<div className="row mb-3  py-2">
<div className="col-md-12">
<div className="table-widget">
      <table className="table border">
<thead>
<tr>
<th>Rack Name</th>
<th> Rack Total Stock </th>

  </tr>
  </thead>
  <tbody>
  {

this.state.rack.map((obj) => { 
console.log(obj)
return(	
<>
<tr>
  <td> {obj.rack_name} </td>
  <td>{obj.rack_total_stock}</td>
  {/* <td>{obj.godown_stock}</td>
 <td>{obj.rack_total_stock}</td> */}
  </tr>
  </>
)})}

  </tbody>
  </table>
  </div>

</div>


  </div>




  <h3 className="text-start">DEFINITION FOR ROLES</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Phone Number</th>
      <th>Email Address</th>
      <th>Username</th>
      <th>Default (Owner)</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.user.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.name}  </td>
      <td>{obj.phone}  </td>
      <td>{obj.email}  </td>
      <td>{obj.user_name}  </td>
      <td>{obj.role_name}  </td>
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>
<h3 className="text-start">EMPLOYEES OPENING BALANCES</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Phone Number</th>
      <th>Email</th>
      <th>Designation</th>
      <th>Username</th>
      <th>Debit/Credit</th>
      <th>Opening Balance</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.employee.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.name}  </td>
      <td>{obj.address}</td>
      <td>{obj.phone}</td>
      <td>{obj.email}</td>
      <td>{obj.designstion}</td>
      <td>{obj.user_name}</td>
      <td>{obj.debit_credit}</td>
      <td>{obj.opening_balance}</td>

      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>


  <h3 className="text-start">Customer Opening Balance</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Phone No</th>
      <th>Address</th>
      <th>Credit Limit Amount</th>
      <th>Credit Limit period</th>
      <th>Whatsapp No</th>
      <th>Debit/Credit</th>
      <th>Opening Balance</th>
     <th>Vehicle No</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.customer.map((obj) => {
return(	
    <>
    <tr>
      <td>{obj.name}  </td>
      <td>{obj.email}  </td>
      <td>{obj.phone}  </td>
      <td>{obj.address}  </td>
      <td>{obj.credit_limit}  </td>
      <td>{obj.credit_limit_period}  </td>
      <td>{obj.whatsapp_no}  </td>
      <td>{obj.debit_credit}  </td>
      <td>{obj.opening_balance}  </td>
      <td>{obj.vehicle_number}  </td>
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">Creditor/Debtor opening balance</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Phone No</th>
      <th>Address</th>
      <th>Credit Limit</th>
      <th>Credit Limit Period</th>
      <th>Whatsapp No</th>
      <th>Debit/Credit</th>
      <th>Opening Balance</th>
    
      </tr>
      </thead>
      <tbody>
      {

this.state.creditor_debtor.map((obj) => {
return(	
    <>
    <tr>
      <td>{obj.name}  </td>
      <td>{obj.email}  </td>
      <td>{obj.phone}  </td>
      <td>{obj.address}  </td>
      <td>{obj.credit_limit}  </td>
      <td>{obj.credit_limit_period}  </td>
      <td>{obj.whatsapp_no}  </td>
      <td>{obj.debit_credit}  </td>
      <td>{obj.opening_balance}  </td>
      
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>


<h3 className="text-start">DIGITAL PAYMENT CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Digital Payment Type</th>
      <th>Reminder Time for Settlement</th>
      <th>Accounting Name</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.payment_methods.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.method_name}  </td>
      <td>{obj.settlement_time}</td>
      <td>{obj.ac_head}</td>
      
      
      
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">BANKS OPENING BALANCES</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Phone No</th>
      <th>Email</th>
      <th>Branch</th>
      <th>Account Number</th>
      <th>Account Type</th>
      <th>Digital Payment</th>
      <th>Opening Balance</th>
      <th>Account Name</th> 
      
      </tr>
      </thead>
      <tbody>
      {

this.state.bank_details.map((obj) => {
return(	
    <>
    <tr>
      
      <td>{obj.bank_name}  </td>
      <td>{obj.address}  </td>
      <td>{obj.phone}  </td>
      <td>{obj.email}  </td> 
      <td>{obj.branch}  </td>
      <td>{obj.account_number}  </td>
      <td> {account_type[obj.account_type] ?account_type[obj.account_type]:''} </td>
      <td>{obj.digital_payment }</td>
      <td>{obj.opening_balance}</td>
      <td>{obj.ac_name}</td>
      
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">FLEET CARD CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Card</th>
      <th>Opening Balance</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.fleet_card.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.accounts_head}  </td>
      <td>{obj.amount}</td>
      
      
      
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">MLA COUPON CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Card</th>
      <th>Opening Balance</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.mla_coupon.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.accounts_head}  </td>
      <td>{obj.amount}</td>
      
      
      
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>




<h3 className="text-start">VENDOR CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Phone Number</th>
      <th>Email</th>
      <th>Credit Period per Bill</th>
      <th>Product Name</th>
      <th>Debit/Credit</th>
      <th>Opening Balance</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.vendor.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.name}  </td>
      <td>{obj.address}</td>
      <td>{obj.phone}</td>
      <td>{obj.email}</td>
      <td>{obj.credit_period}</td>
      <td>{obj.product_name}</td>
      <td>{obj.debit_credit}</td>
      <td>{obj.opening_balance}</td>

      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>


{/* <h3 className="text-start">MACHINERIES/ASSETS</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12"> */}
    {/* <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Machinery/Asset Name</th>
      <th>Date of Purchase</th>
      <th>Purchase Price</th>
      <th>Current Value</th>
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.asset.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.asset_name}  </td>
      <td>{obj.date_of_purchase}</td>
      <td>{obj.purchase_price}</td>
      <td>{obj.current_price}</td>

      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div> */}

<h3 className="text-start">OTHER INCOME CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Other Income Subhead</th>
      
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.other_income.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.accounts_head}  </td>
      

      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">EXPENSES</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Expense Head</th>
      <th>Expense Limt (amount)</th>
      
      
    
      </tr>
      </thead>
      <tbody>
      {

this.state.expense.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.accounts_head}  </td>
      <td>{obj.limit_amount}</td>
      

      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>
  

<h3 className="text-start">DEALERSHIP CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Name</th>
      <th>Address</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Opening Balance</th>
      <th>Debit/Credit</th>
      <th>Product Names	</th>
      <th>Supply Terminal	</th>
      <th>Lead Time to Supply</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.dealership.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.dealer_name}  </td>
      <td>{obj.address}</td>
      <td>{obj.phone}</td>
      <td>{obj.email}</td>
      <td>{obj.opening_balance}</td>
      <td>{obj.debit_credit}</td>
      <td>{obj.fuel_names.map((fuelName) => {
        return (
          <React.Fragment>
            <span>{fuelName}</span> 
            <br />
          </React.Fragment>
        );
      })}</td>
      <td>{obj.supply_terminal}</td>
      <td>{obj.lead_time}</td>
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>



<h3 className="text-start">OWN TANKER CONFIGURATION</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Registration Number</th>
      <th>Total Capacity(KL) </th>
      <th>Number of Containers</th>
      <th>Total Tank Capacity(KL)</th>
      <th>Capacity of Container 1</th>
      <th>Capacity of Container 2</th>
      <th>Capacity of Container 3</th>
      <th>Capacity of Container 4</th>
      <th>Tanker Income Head</th>
      <th>Tanker Expense</th>
      
      </tr>
      </thead>
      <tbody>
      {

this.state.tanker.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.reg_number}  </td>
      <td>{obj.total_capacity}</td>
      <td>{obj.no_of_containers}</td>
      <td>{obj.total_capacity}</td>
      <td>{obj.capacity_of_con1}</td>
      <td>{obj.capacity_of_con2}</td>
      <td>{obj.capacity_of_con3}</td>
      <td>{obj.capacity_of_con4}</td>
      <td>{obj.income}</td>
      <td>{obj.expense}</td>
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">TASK AND REMINDERS</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Task/Reminder</th>
      <th>Description</th>
      <th>Last Action Date</th>
      <th>Repeat Frequency</th>
      <th>Frequency Unit</th>
      <th>Next Action Date</th>
      <th>Remind Before</th>
      <th>Remind From</th>
      <th>Stop After</th>
      <th>Stop After Days</th>
      
      
      </tr>
      </thead>
      <tbody>
      {

this.state.task.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.task}  </td>
      <td>{obj.description}</td>
      <td>{obj.last_action}</td>
      <td>{obj.frequency}</td>
      <td>{obj.frequency_unit}</td>
      {/* <td>{obj.last_action}</td> */}
      <td>{obj.next_action}</td>
      <td>{obj.remind_before}</td>
      <td>{obj.remind_from}</td>
      <td>{obj.stop_after}</td>
      <td>{obj.stop_after_unit > 0 ? obj.stop_after_unit : ""}</td>       
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>

<h3 className="text-start">Fuel unloading configuration</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Fuel Unloading Process</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.fuel_unload.map((obj) => {
  console.log(obj);
return(	
    <>
    <tr>
      <td>{obj.fuel_unload}  </td>   
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>



			</div>
     
     <div class="text-end mt-4 ">
      {this.props.location.pathname == "/config-report/config-report"?
      <span class=" btn btn-success "> <a href="/cbalance-sheet/cbalance-sheet" class="text-white">View C-Balance Sheet</a></span>:
      <span class=" btn btn-success "> <a href="/cbalance-sheet" class="text-white">View C-Balance Sheet</a></span>
      }
      </div>
		</div>
      
	</div>
</div>

		</div>
		
	</div>
</div>





        )
    }
}

export default NonfuelConfigReport