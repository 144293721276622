import React from "react";
import { Component } from "react";
import '../../App.css'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { Link } from "react-router-dom";
class Settings extends Component {
	constructor(props) {
		super(props);

		let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
		this.state = {
			permissions: permissions,
		}

	}
	render() {

		return (



			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress progress={13} />
					<div className="content-area">
						<LoginHeader page_title="" />



						<div className="container pb-3">
							<div className="row justify-content-center mt-md-5 mt-3 form-container">
								<div className="col-md-10">


									<div className="form-wrap py-3">
										<h3>Settings</h3>
										<ul className="settings-menu">
											{this?.state?.permissions?.includes("Deny Pump Details") ? '' :
												<li><Link to="/pump-details"><a href="/pump-details">Pump Details <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											}
											<li><Link to="/fuel-config"><a href="/fuel-config">Fuel Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/tank-config"><a href="/tank-config">Tank Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/nozzle-config"><a href="/nozzle-config">Nozzle Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/target-config"><a href="/target-config">Target Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/products"><a href="/products">Non Fuel Product Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/rack-config"><a href="/rack-config">Rack Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/rack-product-list"><a href="/rack-product-list">Rack Product Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{this?.state?.permissions?.includes("Definition For Roles") ?
												<li><Link to="/users"><a href="/users">Users And Non Users<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											<li><Link to="/customers"><a href="/customers">Customers Opening Balance <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/creditor-debitor"><a href="/creditor-debitor">Creditors/Debtors Opening Balance <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/digital-payment"><a href="/digital-payment">Digital Payment Systems <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/bank-opening"><a href="/bank-opening">Banks Opening Balance <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/fleet-card-config"><a href="/fleet-card-config">Fleet card configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/MLA-coupon"><a href="/MLA-coupon">MLA Coupon Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{/* <li><Link  to="/employee"><a href="/employee">Employees Opening Balance <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li> */}
											<li><Link to="/vendor-config"><a href="/vendor-config">Vendor Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/dealership-config"><a href="/dealership-config">Dealership Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/open-balance"><a href="/open-balance">Open Balance <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/owntanker-config"><a href="/owntanker-config">Own Tanker Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/tanker-income"><a href="/tanker-income">Tanker Income <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/tanker-income"><a href="/tanker-income">Tanker Expense <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/assets"><a href="/assets">Machineries/Assets <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/other-income"><a href="/other-income">Other Income Configuration <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/expenses"><a href="/expenses">Expenses <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/task-config"><a href="/task-config">Task And Reminders <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/fuel-unloading"><a href="/fuel-unloading">Fuel Unloading Configuration<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{/* <li><Link  to="/permission"><a href="/permission">Permissions<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
		   <li><Link  to="/role-list"><a href="/role-list">Role List<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li> */}
											<li><Link to="/operational-date"><a href="/operational-date">Operational Settings<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
										</ul>

									</div>

								</div>
							</div>
						</div>
					</div>


				</div>
			</div>

		)
	}
}
export default Settings;

