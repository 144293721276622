import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import FuelUnloadingConfig2 from './FuelUnloadingConfig2.component'
import moment from 'moment';
// import FuelUnloadingdensityModal from './FuelUnloadingDensityModal.component'
const FuelUnloadingConfig1Modal = ({ preventNonNumericalInput, prevNonNumericalWithDec, isShowing, startDate, fuel_config, handleCapacityView, showFuelTanker, version, unloading, double, drop, containerData, handleTankerSubmit, input, input1, handleIn, showFuelTanker1, handleDate, dropdown, handleInput, handleInput1, handleSelect, handleSelect1, handleSelect2, showFuelTanker5, showFuelTanker2, showFuelTanker3, showFuelTanker4, Example, Example4, Example1, Example5, Example2, Example3, hide, setVehicle, errors, success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs,currentValue,currentValuequantity}) => {

  var today = new Date().toISOString().split('T')[0];
  {console.log(currentValue)}
  {console.log(currentValuequantity)}
  return (
    <React.Fragment>

      {console.log(startDate)}
    
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal  modalxl ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Invoice details</h5>
            {/* {currentValue==inputValues.invoice_amount  && currentValuequantity==input.total_capacity ?  */}
            <button type="button" id="closemodal" className="modal-close-button" data-dismiss="modal modalxxl" aria-label="Close" onClick={hide}>
            

              <span aria-hidden="true">&times;</span>
            </button> 
            {/* <button type="button" id="closemodal" className="modal-close-button" data-dismiss="modal modalxxl" aria-label="Close" onClick={hide2}>
            

              <span aria-hidden="true">&times;</span>
            </button> } */}
          </div>


          <div className="row py3 mt-3 ">
            <div className="col-md-4">
              <div className="form-check">

                <a>   <input className="form-check-input" type="radio" id="exampleRadios1" name="tanker" value="own_tanker" onChange={handleChange} />
                </a><label className="form-check-label " for="examplecheckboxs1">   Own Tanker
                </label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-check">

                <input className="form-check-input" type="radio" id="exampleRadios1" name="tanker" value="other_tanker" onChange={handleChange} />
                <label className="form-check-label " for="examplecheckboxs1">  Other Tanker
                </label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group mb-3 d-flex">
                <label className=" d-none me-3">Date</label>
              </div>
              <input type="date" name="op_date" className="form-control d-none" value={inputValues.invoice_date} max={input1.end_date} onChange={handleDate}></input> <div className="errorMsg">{errors.op_date}</div>
            </div>

            {inputValues.tanker == 1 &&
              <>
                <div className="row">
                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label className="">Registration Number<span className="star">*</span></label>

                      <div className="select-container">
                        <select className="form-control " name="reg_number" value={inputValues.reg_number} onChange={handleInput1} >
                          <option>Select</option>
                          {
                            dropdownData.map((data) => {

                              return <option value={data[0]}>{data[1]}</option>
                            })}
                        </select>
                        <div className="errorMsg">{errors.reg_number}</div>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label className="">Tanker Capacity in KL</label>

                      <input type="text" className="form-control  "
                        name="total_capacity" placeholder="" value={dropdown.total_capacity} readOnly />
                    </div>
                  </div>
                  <div className="col-md-4 mt-4">

                    <label className="">No of Compartment</label>
                    <div className="select-container">
                      <select className="form-control  " name="no_of_containers" disabled >
                        {
                          containerData.map((data) => {

                            return <option value={data[0]}>{data[1]}</option>
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                {drop.container == 1 &&
                  <div className="row mt-4">

                    <div className="col-md-4">
                      <div className="form-group mb-3 ">
                        <label className="">Capacity of Container in KL </label>

                        <input type="text" className="form-control "
                          name="capacity_of_con1" placeholder="" value={drop.capacity_of_con1} readOnly />
                      </div>
                    </div>
                  </div>
                }

                {drop.container == 2 &&
                  <>
                    <div className="row mt-4">

                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="">Container no 1 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con1" placeholder="" value={drop.capacity_of_con1} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con1}</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="">Container no 2 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con2" placeholder="" value={drop.capacity_of_con2} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con2}</div>

                        </div>
                      </div>

                    </div>
                  </>
                }

                {drop.container == 3 &&
                  <>
                    <div className="row mt-4">

                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="">Container no 1 Capacity in KL</label>

                          <input type="text" className="form-control  "
                            name="capacity_of_con1" placeholder="" value={drop.capacity_of_con1} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con1}</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="">Container no 2 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con2" placeholder="" value={drop.capacity_of_con2} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con2}</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="col-md col-form-label mb-1">Container no 3 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con3" placeholder="" value={drop.capacity_of_con3} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con3}</div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {drop.container == 4 &&
                  <>
                    <div className="row mt-4">

                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="" style={{ fontSize: 12 }}>Container no 1 Capacity in KL</label>

                          <input type="text" className="form-control  "
                            name="capacity_of_con1" placeholder="" value={drop.capacity_of_con1} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con1}</div>

                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="" style={{ fontSize: 12 }}>Container no 2 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con2" placeholder="" value={drop.capacity_of_con2} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con2}</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="" style={{ fontSize: 12 }}>Container no 3 Capacity in KL</label>

                          <input type="text" className="form-control "
                            name="capacity_of_con3" placeholder="" value={drop.capacity_of_con3} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con3}</div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label className="" style={{ fontSize: 12 }}>Container no 4 Capacity in KL</label>
                        
                          <input type="text" className="form-control"
                            name="capacity_of_con4" placeholder="" value={drop.capacity_of_con4} readOnly />
                          <div className="errorMsg">{errors.capacity_of_con4}</div>

                        </div>
                      </div>
                    </div>
                  </>
                }
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="form-group mb-3 ">

                      <label className="">Invoice Number<sub className="star">*</sub></label>

                      <input type="number" className="form-control hide-arrow" name="invoice_no" value={inputValues.invoice_no} onChange={handleInputs} />
                      <div className="errorMsg">{errors.invoice_no}</div>

                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="form-group mb-3 ">
                      <label className="">Invoice Date<sub className="star">*</sub></label>

                      <input type="date" className="form-control cal-date " name="invoice_date" id={"m_op_date"}  value={inputValues.invoice_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :inputValues.invoice_date} min={startDate} max={today} onChange={handleInputs} disabled />
                      <div className="errorMsg">{errors.invoice_date}</div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group mb-3 ">

                      <label className="">Invoice Amount<sub className="star">*</sub></label>

                      <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow " name="invoice_amount" value={inputValues.invoice_amount} onChange={handleInputs} />
                      <span id="words" class="amount-words"></span>
                      <div className="errorMsg">{errors.invoice_amount}</div>
                     
                    </div>
                  </div>
                </div>

                {inputValues.tanker == 1 &&
                  <div className="modal-footer justify-content-end p-0">
                    <a className="btn btn-primary w-auto  m-btn " onClick={handleSubmit}  >Next</a></div>
                }
              </>
            }
          </div>

          {inputValues.tanker == 2 &&
            <>
              <div className="row">
                <div className="col-md-4 mt-4">
                  <div className="form-group mb-3 ">
                    <label className="">Registration Number<sub className="star">*</sub></label>
                    <input type="text" className="form-control " name="reg_number" value={input.reg_number} onChange={handleSelect2} />
                    <div className="errorMsg">{errors.number}</div>

                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <div className="form-group mb-3 ">
                    <label className="">Tanker Capacity in KL<sub className="star">*</sub></label>
                    <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control  hide-arrow" name="total_capacity" value={input.total_capacity} onChange={handleSelect2} />
                    <div className="errorMsg">{errors.total_capacity}</div>

                  </div>
                </div>




                <div className="col-md-1 mt-5">
                  <a style={{ fontSize: 20 }} onClick={handleCapacityView}><i className="fa fa-arrow-down other_tanker_details"></i></a>
                </div>
                <div className="col-md-4 mt-4">
                  <div className="form-group mb-3 ">
                    {console.log(input.no_of_containers)}
                    <label className="">No of Compartment<sub className="star">*</sub></label>
                    <select className="form-control  w-90" name="no_of_containers" value={input.no_of_containers} onChange={handleIn}>
                      <option value="0">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <div className="errorMsg">{errors.no_of_containers}</div>
                  </div>
                </div>

              </div>
              {input.no_of_containers == 1 &&
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group mb-3 ">

                      <label className="">Capacity of Container in KL<span className="star">*</span> </label>

                      <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow "
                        placeholder="" name="capacity_of_con1" value={input.capacity_of_con1} onChange={handleSelect} />
                      <div className="errorMsg">{errors.capacity_of_con1}</div>
                    </div>
                  </div>
                </div>
              }
              {input.no_of_containers == 2 &&
                <>
                  <div className="row mt-4">

                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="">Container no 1 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow "
                          name="capacity_of_con1" placeholder="" value={input.capacity_of_con1} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con1}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="">Container no 2 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow "
                          name="capacity_of_con2" placeholder="" value={input.capacity_of_con2} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con2}</div>

                      </div>
                    </div>
                  </div>
                </>
              }

              {input.no_of_containers == 3 &&
                <>
                  <div className="row mt-4">

                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="">Container no 1 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow "
                          name="capacity_of_con1" placeholder="" value={input.capacity_of_con1} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con1}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="">Container no 2 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow"
                          name="capacity_of_con2" placeholder="" value={input.capacity_of_con2} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con2}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="">Container no 3 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow"
                          name="capacity_of_con3" placeholder="" value={input.capacity_of_con3} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con3}</div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {input.no_of_containers == 4 &&
                <>
                  <div className="row mt-4">
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="" style={{ fontSize: 13 }}>Container no 1 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control  hide-arrow"
                          name="capacity_of_con1" placeholder="" value={input.capacity_of_con1} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con1}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="" style={{ fontSize: 13 }}>Container no 2 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow"
                          name="capacity_of_con2" placeholder="" value={input.capacity_of_con2} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con2}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="" style={{ fontSize: 13 }}>Container no 3 Capacity in KL</label>

                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow"
                          name="capacity_of_con3" placeholder="" value={input.capacity_of_con3} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con3}</div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-group mb-3 ">
                        <label className="" style={{ fontSize: 13 }}>Container no 4 Capacity in KL</label>
                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow"
                          name="capacity_of_con4" placeholder="" value={input.capacity_of_con4} onChange={handleSelect} />
                        <div className="errorMsg">{errors.capacity_of_con4}</div> </div>
                    </div>
                  </div>
                </>
              }
              <div class="row mt-4">
                <div class="col-md-4">
                  <div class="form-group mb-3 ">
                    <label className="">Invoice Number<sub className="star">*</sub></label>
                    <input type="number" className="form-control hide-arrow" name="invoice_no" value={input.invoice_no} onChange={handleSelect1} />
                    <div className="errorMsg">{errors.invoice_number}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3 ">
                    <label className="">Invoice Date<sub className="star">*</sub></label>
                    <input type="date" className="form-control cal-date" name="invoice_date" id={"m_op_date"}  value={input.invoice_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :input.invoice_date} min={startDate} max={today} onChange={handleSelect1} disabled/>
                    <div className="errorMsg">{errors.date}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3 ">
                    <label className="">Invoice Amount<sub className="star">*</sub></label>
                    <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow" name="invoice_amount" value={input.invoice_amount} onChange={handleSelect1} />
                    <span id="words" class="amount-words"></span>
                    <div className="errorMsg">{errors.amount}</div>
                  </div>
                </div>

              </div>
            </>
          }
          <div className="col-md-10">
          </div>
          {inputValues.tanker == 2 &&
            <div className="modal-footer justify-content-end p-0">
              <div className="text-end mt-4 "><a className="btn btn-primary m-btn w-auto " onClick={handleTankerSubmit}  >Next</a></div></div>
          }
          {showFuelTanker ? <div className="mt-5">
            <FuelUnloadingConfig2
              unloading={unloading}
            />
          </div>
            : null}
        </div>
      </div>
    </React.Fragment >
  );
}
export default FuelUnloadingConfig1Modal;