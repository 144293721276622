
import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import NozzleEmpConfigModal from './NozzleEmpModal.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import { preventNonNumericalInput } from '../../../layouts/Operational_Sidebar_Progress.component';
import { inArray } from 'jquery';
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';

const format = 'HH:mm'


const NozzleModal = (props) => ReactDOM.createPortal(
	<NozzleEmpConfigModal  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const NozzleAlloEmplist = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [input, setInput] = useState({});
	const [input1, setInput1] = useState({});
	const [input2, setInput2] = useState({});
	const [fields, setFields] = useState(['']);
	const [chek_nz, setFields2] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState([])
	const [dropdownData, setDropdownData] = useState([])
	const [nozzledropdownData, setNozzleDropdownData] = useState([])
	const [double, setDouble] = useState(true);
	const [permission, setPermission] = useState([])
	const [open_reading, setOpenReading] = useState([])
	const [Loading, setLoading] = useState(false)
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	//---On Load---//		
	useEffect(() => {
		check_url();
	}, []);

	const handleChange = (e) => {

		//   setInput({...input, config_date:e.target.value})
		setInputValues({ ...inputValues, op_date: e.target.value })
		getEmployee_nozzle(e.target.value);

		localStorage.setItem('op_date', e.target.value);
	}
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				console.log(responseData)
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					getEmployee_nozzle();

				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}

	function getEmployee_nozzle(date) {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				op_date: date
			})
		};
		fetch(API_URL + "/get_employee_name_nozzle", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInput({ ...input, config_date: responseData.start_date })
				setInput1({ ...input1, end_date: responseData.end_date })
				setInputValues({
					...inputValues,
					op_date: responseData.op_date,
					role: responseData.role,
					sugest_start_from: responseData.sugest_start_from != '' ? moment(responseData.sugest_start_from, 'HH:mm:ss') : null,
                    start_from: !responseData.start_from ? moment(responseData.sugest_start_from, 'HH:mm:ss') : moment(responseData.start_from, 'HH:mm:ss')

				})
				var datas = []
				responseData.employee.forEach(emp => {
					datas.push([emp.emp_id, emp.emp_name])
				})
				setDropdownData(datas);
				var Nozzledatas = []
				responseData.nozzle.forEach(noz => {
					Nozzledatas.push([noz.id, noz.nozzle_name])
				})
				setNozzleDropdownData(Nozzledatas);
				ListEmployee(pages.current_page, responseData.op_date);
				return responseData;
			})
			.catch(e => {
				console.log(e);
			});
	}
console.log(inputValues,"yaro")
	function ListEmployee(page, date) {
		const requestOptions = {      //---employee listing---//
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				op_date: date,
				search_keyword: search
			})
		};
		fetch(`${API_URL}/allocated-employee-list?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })

				}
				else {
					setList(responseData.data.data)
					setInput2({ ...input2, isdayclose: responseData.isdayclose,has_price_change: responseData.has_price_change  })
					setpages({
						...pages,
						current_page: responseData.data.current_page,
						last_page: responseData.data.last_page,
						per_pages: responseData.data.per_page,
						total_pages: responseData.data.total,

					})

				}
				setLoading(true)
				return responseData;
			})
			.catch(e => {
			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListEmployee(number)
	}
	// save op_emp_id to local storage //
	function handleOpEmpId(op_emp_id, emp_id, emp_name, op_date) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('save_hide', 'false');
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);
		localStorage.setItem('op_date', op_date);

		history.push("/nozzle-closing")
	}
	function handleOpEmpIdEye(op_emp_id, emp_id, emp_name, op_date) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('save_hide', 'true');
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);
		localStorage.setItem('op_date', op_date);

		history.push("/nozzle-closing")
	}
	function handleReport(op_emp_id, emp_id, emp_name) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);

		history.push("/op-worksheet")
	}
	function handleEdit(id) {

	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {

			var isdayclose = input2.isdayclose;
			var has_price_change = input2.has_price_change;
			if (isdayclose == 1) { 
				swal("", "Your Current Op-Date has already been Day-Closed, Please change Op-Date", false ? "success" : "warning")
			}else if(has_price_change == 0){
				swal("", "Please Price change for the current operation date selected", false ? "success" : "warning")
			}
			else {

				getEmployee_nozzle(inputValues.op_date);
				if (isShowing) {
					setErrors({})
					inputValues.employee_id = "";
					inputValues.start_from = "";
					inputValues.opening_cash = "";
					inputValues.sugest_start_from = "";
					setInputValues(inputValues)
					setFields([''])
					setFields2([''])
				}
				if (!isShowing) {

					setErrors({})
					setDouble(true)
				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	inputValues['start_from'] = inputValues.start_from;
	inputValues['sugest_start_from'] = inputValues.sugest_start_from;



	const handleInputs = (e) => {

		if (inputValues['start_from'] == null) {
			inputValues['start_from'] = inputValues['sugest_start_from'];
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});

	};
	const handleInput = (value) => {
		console.log(value,"start")
		setInputValues({
			...inputValues,
			start_from: value
		})
	};

	//---onchange functions---//

	function handleChange_nozzle_id(i, event) {
		const values_nozzle = [...fields];
		values_nozzle[i] = event.target.value;
		setFields(values_nozzle);
		setInputValues({ ...inputValues, nozzle_id: values_nozzle })
		getOpenReading(event.target.value, i);

		disable_nz(); // to disable nozzle (handle change)
	}
	function disable_nz() {
		var elss = [];
		var els = document.getElementsByClassName('nozzle_ids');

		for (var i = 0; i < els.length; i++) {
			if (els[i].value != 0) {
				elss[i] = parseInt(els[i].value);
			}
		}
		setFields2(elss);
	}
	function getOpenReading(id, i) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ nozzle_id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(API_URL + "/get_opening_reading", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var op = responseData.close_reading;
				var s = document.getElementById("opening_reading" + i);
				s.value = op;


				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function handleAdd() {

		const values_nozzle = [...fields];

		values_nozzle.push('');
		setFields(values_nozzle);
		setErrors([])
	}
	function handleDelete(i) {
		const values = [...fields];

		console.log(values)
		values.splice(i, 1);
		setFields(values);
		setInputValues({ ...inputValues, nozzle_id: values })

		for (var i = 0; i < values.length; i++) {
			if (values[i] != 0) {
				values[i] = parseInt(values[i]);
			}
		}
		setFields2(values);
	}
	function handleRemove(i, event) {

		var i = fields.length - 1
		if (i != 0) {
			const values_nozzle = [...fields];
			values_nozzle.splice(i, 1);
			setFields(values_nozzle);
			setInputValues({ ...inputValues, nozzle_id: values_nozzle })
		}


	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		//event.preventDefault();

		setDouble(false);
		if (validateForm())
			sendNozzleData()
	}
	//---validations---//	
	function validateForm() {

		let errors = []
		let formIsValid = true

		if (!inputValues.employee_id) {
			formIsValid = false;
			setDouble(true)
			errors["employee_id"] = "Select Employee Name"
		}
		else {
			errors["employee_id"] = ""
		}
		if (!inputValues.start_from) {
			formIsValid = false;
			setDouble(true)
			errors["start_from"] = "Select Start From"
		}
		else {
			errors["start_from"] = ""
		}
		fields.map((inpuvalue, idx) => {

			if (!inpuvalue || inpuvalue == 0) {
				formIsValid = false;
				setDouble(true)
				errors["nozzle_id" + idx] = "Select Nozzle"

			}
			else {
				errors["nozzle_id" + idx] = ""
			}

		})
		setErrors(errors)

		return formIsValid;
	}
	//---Create EmployeeList---//
	function sendNozzleData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				employee_id: inputValues.employee_id,
				nozzle_id: inputValues.nozzle_id,
				op_date: inputValues.op_date,
				//opening_cash: inputValues.opening_cash,
				role: inputValues.role,
				start_from: inputValues.start_from.format(format),
				
			})
		};
		fetch(API_URL + "/nozzle_allocation", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["opening_balance"] = responseData.response.opening_balance
					setDouble(true)
				}
				if (responseData.status == 3) {
					errors["nozzle_id"] = responseData.message
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/nozzle-employee-list")
					setDouble(true)
					setErrors([''])
					setInputValues([])
					toggle();
					check_url();
					//ListEmployee(pages.current_page);

				}
				if (responseData.status == 11) {
					swal("", responseData.message, responseData.status ? "warning" :"success"  )
					history.push("/nozzle-employee-list")
					setDouble(true)
					setErrors([''])
					//setInputValues([])
					// toggle();
					//check_url();
					//ListEmployee(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				setDouble(true)

			});
	}




	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}

	function handleEmpAproval(id, status) {

		approvalId(id)
		approvalStatus(status)
		toggleModal()
	}

	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/employee_closing_approve_reject`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				history.push("/nozzle-employee-list")
				check_url();
				toggleModal();
				return responseData;
			})
			.catch(e => {


			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={25} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />

						<div className="container pb-3">
							<div class="row">
								<div className="col-md-12 mb-3 px-4 mt-3">
									<Link to="/operational-date">
										<a href="/operational-date" className="float-end btn btn-gray">BACK</a>
									</Link></div></div>

						</div>

						<div class="container pb-3">


							<div class="row mt-md-5 mt-3 form-container">

								<div className="col-md-12">
									{Loading ? ListEmployee :
										// <div class="loader text-primary">Loading...</div>
										<Loader />
									}

									<div className="form-wrap list-wrp">

										<div className="row mb-3">
											<div className="col-md">
												<div className="row">
													<div className="col-md-1 col-2 pe-0">
														<img src={customer} width="25px" className="img-fluid" alt=".." />
													</div>
													<div className="col-md-6 col pl-0">

														<h3 className="table-title">Employee Closing </h3>
														<h4 className="table-title">{moment(inputValues.op_date).format("DD-MM-YYYY")}</h4>

													</div>
													<div className="col-md-1">
														<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#add-customer" onClick={toggle}>Nozzle Allocation</a>
													</div>
												</div>
												<p2 className="note mt-2">This is an Action Button for Worksheet Preparation of Nozzle Allocation </p2>
											</div>
											<div className="col-md-3">

												<input type="date" class="form-control" name="startDate" min={input.config_date} max={input1.end_date} onChange={handleChange} value={inputValues.op_date} disabled />

											</div>
											<div className="col-md-3">
												<div className="input-group mb-3 search">
													<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
													</span>
													<input type="text" className="form-control" placeholder="Search with name " title="Search with name " value={search.search_keyword} onChange={handleSearch} />
													<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

												</div>
											</div>
										</div>
									

										{permission.status == 3 ?
											<div className="form-wrap">
												<div className="text-center">
													{permission.message}
												</div></div> :
												
											<div className="table-widget">
												<table className="table border status-table">
													<thead>
														<tr>
															<th>Allocated Empolyees Names</th>
															<th>Short/Excess</th>
															<th>Start Time</th>
															<th>End Time</th>
															{/*<th>Opening Cash</th>*/}
															<th>Status</th>
															<th>Action</th>
														</tr>
													</thead>

													<tbody>
														{

															list_data.map((obj) => (
																
																<tr>
																	{/* {console.log(obj.sort_or_excess.short_excess_list,obj.sort_or_excess.op_end_from,obj.sort_or_excess.op_start_from,"object")} */}
																	<td>{obj.emp_name}</td>
																	{/*}	<td>{obj.opening_cash}</td>*/}
																    <td>{obj.sort_or_excess.short_excess_list}</td>
																	<td>{obj.sort_or_excess.op_start_from}</td>
																	<td>{obj.sort_or_excess.op_end_from}</td>
																	<td>{obj.status == 3 ? <a href="#" class="btn btn-success btn-xs">Approved</a> : (obj.status == 4) ? <a href="#" class="btn btn-success btn-xs">Day Closed</a> : ((obj.status == 2)) ? <a href="#" class="btn btn-warning btn-xs">Approval Pending</a> : ((obj.status == 1)) ? <a href="#" class="btn btn-warning btn-xs">Pending</a> : ''} </td>
																	<td><div className="d-flex icon-btn">
																		{/* <button onClick={(event) => history.push("/nozzle-allocation/"+obj.id)} >  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
																	</button> */}
																		{((obj.start_from != null && (obj.status < 3))) ? <button onClick={() => handleOpEmpId(obj.id, obj.emp_id, obj.emp_name, obj.op_date)} >  <i className="fa fa-ellipsis-h" aria-hidden="true" title="Nozzle Closing"></i></button> : ''}
																		{obj.status >= 3 ?<button onClick={() => handleOpEmpIdEye(obj.id, obj.emp_id, obj.emp_name, obj.op_date)} >  <i className="fa fa-eye" aria-hidden="true" title="View"></i></button>:""}
																		{((obj.start_from != null)) ? <button onClick={() => handleReport(obj.id, obj.emp_id, obj.emp_name)} >  <i className="fa fa-file-text" aria-hidden="true" title="WorkSheet"></i></button> : ''}
																		{permissions.includes("Deny Approval") == true ?
																			<>
																				{((obj.status != 1) && (obj.status != 4) && inputValues.role != "employee") ? <button onClick={() => handleEmpAproval(obj.id, obj.status)}>{(obj.status == 3) ? <i className="text-success fa fa-check" aria-hidden="true" title="Reject"></i> : <i className="text-warning fa fa-check" aria-hidden="true" title="Approval"></i>}</button> : ''}
																			</>
																			: ''}
																	</div></td>
																</tr>
															))}
													</tbody>
												</table>


											</div>}
										{Loading ? <PaginationComp
											pages={pages}
											handlePagination={handlePagination}
										/> : ""
										}
									</div>
									{/* <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/creditor-debitor")}>Skip/Continue</a></div> */}
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
			{isShowing ? (<NozzleModal
				isShowing={isShowing}
				hide={toggle}
				inputValues={inputValues}
				fields={fields}
				chek_nz={chek_nz}
				handleDelete={handleDelete}
				handleSubmit={handleSubmit}
				handleInputs={handleInputs}
				preventNonNumericalInput={preventNonNumericalInput}
				handleInput={handleInput}
				handleChange_nozzle_id={handleChange_nozzle_id}
				dropdownData={dropdownData}
				open_reading={open_reading}
				double={double}
				nozzledropdownData={nozzledropdownData}
				handleAdd={handleAdd}
				handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}
			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className=" modal-dialogue-xl">
					<div className="modal-content">
						<div className="modal-title modal-header "><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
						<div className=" modal-body  modal-body"><p>Do you want to {approval_status == 2 ? 'Approve' : 'Reject'} ?</p></div>
						<div className="justify-content-end  modal-footer"><div className="d-flex">
							<button type="button" className="btn btn-primary me-2 px-4" onClick={handleYes}>Yes</button>
							<button type="submit" className="btn btn-secondary" onClick={toggleModal}>No</button>
						</div>
						</div>
					</div>
				</div>
			</Modal>

		</>

	)
}








export default NozzleAlloEmplist