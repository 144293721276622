
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import museum from '../../img/museum.svg'
import swal from "sweetalert";
import { useState ,useMemo} from 'react';
import MlaCouponModal from './MlaCouponModal'
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import {convertAmountToWords,preventNonNumericalInput} from '../layouts/Sidebar_Progress.component'
var MlaModal = (props) => ReactDOM.createPortal(
	<MlaCouponModal  {...props} />, document.body
		 );
const MlaCoupon = (location) => {
	const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
	const [double, setDouble] = useState(true);
	const UseModal = () => {
 
		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			var isdayclose = checkoption.isdayclose;
    if(isdayclose == 1)
    {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else
    {
			if (isShowing) {
				setInputValues({})
				
				// setFields([''])
			}
			if (!isShowing) {
				setDouble(true);
				setErrors({})

			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);

	function toggleModal() {
		setIsOpen(!isOpen);
	}


	  const[delete_id ,deleteId] = useState([])
	  const [errors,setErrors] = useState([ ])
	
	const [inputValues,setInputValues] = useState({});
	const [stat_operation, setStartoperation] = useState('')
	const [checkoption, ckeckOption] = useState([])
	const history = useHistory()
	const handleInputs = (e) => {
	
	
	  setInputValues({
		 ...inputValues,
		 [e.target.name]: e.target.value,
		 
	   });

	   convertAmountToWords(e,e.target.value)  
	 };
	 const handleSubmit = (event ) =>
	  {
		event.preventDefault();
		setDouble(false);
		if(validateForm())
		sendMlaDetails()
		
		
	  }

	  function checkSkip()
	   {
		if(check === 'No')
		{
		  swal("Please Complete MLA coupon Configuration")
		}
		else{
		  history.push("/vendor-config")
		}
	   }
	  function	validateForm() {
		let errors = []
		  let formIsValid = true
		
		  if (!inputValues.mla_coupon) {
			  formIsValid = false;
			  setDouble(true);
			  errors["mla_coupon"] = "Select a choice"
		  }
		  if(stat_operation!="Yes" && inputValues.mla_coupon == "yes"){

			if (!inputValues.opening_balance) {
			  setDouble(true)
			  formIsValid = false;
			  errors["opening_balance"] = "Enter Opening Balance"
			}else{
				errors["opening_balance"] = ""
			}
		}
		// console.log(!inputValues.opening_balance,"poo")
		setErrors(errors)
	  
		  return formIsValid;
	  
	  
	  }
	  async function sendMlaDetails()
	  { 
			  const requestOptions = {
			  method: 'POST',
			  headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type":"application/json",
				"Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				"Accept": "application/json"
			   },
				 body: JSON.stringify(inputValues)
		  };
		  await fetch(API_URL+"/mla-coupon",requestOptions)
				  .then(response => 
					{ 
					   return response.json();})
		  .then(responseData => {
			  let errors=[]
			  if(responseData.status == false){
			  	swal("",responseData.message,responseData.status?"success":"warning")
              	errors["opening_alance"] =responseData.response.opening_balance
			  	setDouble(true)				
			  }
			  if(responseData.status == true){
				swal("",responseData.message,responseData.status?"success":"danger")
		   		setDouble(true)
				toggle();
			 	ListMla();
			 }
			 else{
				swal("Something went wrong","","warning")
			 }
				setErrors(errors)
			 	setInputValues("")
			 return responseData;
			}) .catch(e => {
					// swal("Something went wrong","","warning")
					console.log(e); 
					setDouble(true)    
				  });
	  }

	 const [search,setSearch]=useState({})
	 const  handleSearch = (event) =>
	 {
		setSearch(event.target.value)
	 }
   
	
	 useEffect(()=>{
		check_url()
		setLocation(location.location.pathname)	
	 },[search]); 
   
 //------------------------url
 function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			setStartoperation(responseData.stat_operation)
		
			if(responseData.allowurl == 1)
			{
				if(location.location.pathname == "/MLA-coupon/MLA-coupon")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
				ListMla();
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
			console.log(e)
		});

}
// console.log(stat_operation,"ooo")
const [path, setLocation] = useState([])
const [permission, setPermission] = useState([])
	   //loader
const [Loading,setLoading]=useState(false);
	 async function ListMla()
	  {
			  const requestOptions = {
			  method: 'get',
			  headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type":"application/json",
				"Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				"Accept": "application/json"
			
			   },
		  };
		   await fetch(API_URL+"/mla-coupon",requestOptions)
				  .then(response => { return response.json();})
		  .then(responseData => {

			if(responseData.status==3) 
			{
			  setPermission({...permission,status:responseData.status,message:responseData.message})
			}

		 setList(responseData.list) 
		 setCheck(responseData.meta) 

		 setLoading(true)
		 ckeckOption({...checkoption,
			isdayclose:responseData.isdayclose})
	 return responseData;
			})
				  .catch(e => {
					  console.log(e);
					 
				  });
	  }
	  const [list_data,setList] = useState([])
	  const [check ,setCheck ] = useState()
	  async function edit_mla(id)
	  {
   
			const requestOptions = {
			method: 'get', 
			data: {id:id},     
			headers: {
			  "Access-Control-Allow-Origin": "*",
			  "Content-Type":"application/json",
			  "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
			  "Accept": "application/json"
		  
			 },  
		};
		 await fetch(API_URL+"/mla-coupon/"+id+"/edit",requestOptions)
				.then(response => { return response.json();})
		.then(responseData => {
		
		 // setId(responseData) 
		 
		 // console.log(payment_id)
		 setInputValues(
		  {
			opening_balance : responseData.data.amount,
		    mla_coupon: responseData.data.id!=null?'yes':'no',
			id:responseData.data.id ? responseData.data.id : '',
			ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,
		  }
		);
			 toggle();
	  
	  return responseData;
		  })
				.catch(e => {
					console.log(e);
				   
				});
	  }
	  function delete_mlacoupon(id)

     {
		var isdayclose = checkoption.isdayclose;
		if(isdayclose == 1)
		{
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
  else
  {
      
       deleteId(id)
      
       toggleModal()
     }
	}
	 function deleteMlaCoupon()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/mla-coupon/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListMla();
		   setDouble(true)
           toggleModal()
 
       return responseData;
     
   
         })
   
     .catch(err=>{
		 swal("Something went wrong","","warning")
		 console.log(err)
            
         })
  }


  return(
    <>  
  <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/MLA-coupon/MLA-coupon"? <Sidebar_Progress path={path} />:<Sidebar_Progress />}
   <div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
<div className="row mt-3">

 <div className="col-md-12 mb-3 px-4 ">{path == "/MLA-coupon/MLA-coupon"?<Link to={backPage || "/operational-date"}>
        <a href="#" className="float-end btn btn-gray">Go Back</a>
      </Link>:<Link to="/fleet-card-config">
        <a href="fleet-card-config" className="float-end btn btn-gray">Back</a>
      </Link>}</div>
 </div>
	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
			
		{permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
					  {Loading ? ListMla : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} width="25px" className="img-fluid" alt=".."/>
						</div>
							<div className="col-md col pl-0">
							<h3 className="table-title">MLA Coupon Configuration </h3>
						</div>
						<div className="col-md-7">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add MLA Coupon Configuration</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						{/* <div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Card"  value={search.search_keyword} onChange={handleSearch} onKeyPress={(event) => {
    var key = event.keyCode || event.which;
    if (key === 13) {
        
    }
}}    />
</div> */}
					</div>
				</div>


				<div className="table-widget">
					<table className="table  border">
  <thead>
    <tr>
      <th>Card</th>
           <th>Configured Opening Balance</th>
		   <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {list_data.map(mlacoupon => (
   <tr>
   <td>{mlacoupon.get_mla_trans_name ? mlacoupon.get_mla_trans_name.accounts_head : ''}</td>
   <td className="text-end">{mlacoupon.amount}</td>
   <td><div className="d-flex icon-btn">
      <button  onClick={()=> edit_mla(mlacoupon.id)}>  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      {/* <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_mlacoupon(mlacoupon.id)}>  <i className="fa fa-trash" aria-hidden="true"></i> */}

{/* </button> */}


      </div></td>
   
   </tr>
  ))} 

</tbody>
</table>
				</div>
			</div>
			{path == "/MLA-coupon/MLA-coupon"?"":
				<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={checkSkip}>Continue</a></div>
  }
		</div>
		</>}
	</div>
	</div>
	
</div>


</div>
</div>
</div>
{isShowing ? (<MlaModal
        isShowing={isShowing}
        hide={toggle}
         handleInputs={handleInputs}
         inputValues={inputValues}
		 preventNonNumericalInput={preventNonNumericalInput}
        // dropdownData ={dropdownData}
         handleSubmit={handleSubmit}
		 stat_operation={stat_operation}
         errors={errors}
		 double={double}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">MLA COUPON CONFIGURATION</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Mla Coupon</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
					{double == true ? <button type="submit" class="btn btn-primary"  onClick={ deleteMlaCoupon}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={ deleteMlaCoupon}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>

</>
)
  }

export default MlaCoupon